import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"
import Section from "../../components/section"
import Container from "../../components/container"
import Box from "../../components/box"

const DataPrivacyPage = ({ _path, data }) => {
  return (
    <Layout path={"/datenschutz"}>
      <SEO title={"Datenschutz"} />
      <TitleSection />
      <IntroSection />
      <LegalBasisSection />
      <DataSharingSection />
      <DataSharingInternationalSection />
      <DataStorageSection />
      <AutoDecisionSection />
      <WebsiteLogSection />
      <ContactViaTypingSection />
      <ContactViaPhoneSection />
      <YoutubeVideoSection />
      <ApplicationPrivacySection />
      <RevocationSection />
      <DataPrivacySection />
      <ReferenceSection />
    </Layout>
  )
}

const TitleSection = () => (
  <Section id={"TitleSection"}>
    <Container>
      <Box padding={"full"} background={"red"}>
        <h1>Datenschutz</h1>
      </Box>
    </Container>
  </Section>
)

const IntroSection = () => (
  <Section id={"IntroSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <p>
          Wir informieren Sie nachfolgend gemäß den gesetzlichen Vorgaben des Datenschutzrechts (insb. gemäß
          BDSG n.F. und der europäischen Datenschutz-Grundverordnung ‚DS-GVO‘) über die Art, den Umfang und
          Zweck der Verarbeitung personenbezogener Daten durch unser Unternehmen. Diese Datenschutzerklärung
          Zweck der Verarbeitung personenbezogener Daten durch unser Unternehmen. Diese Datenschutzerklärung
          gilt auch für unsere Websites und Sozial-Media-Profile. Bezüglich der Definition von Begriffen wie
          etwa "personenbezogene Daten“ oder "Verarbeitung“ verweisen wir auf Art. 4 DS-GVO.
        </p>
        <strong>Name und Kontaktdaten des / der Verantwortlichen</strong><br />
        Unser/e Verantwortliche/r (nachfolgend "Verantwortlicher“) i.S.d. Art. 4 Zif. 7 DS-GVO ist:<br />
        <br />
        Red Oak Consulting & Services for IT GmbH & Co. KG<br />
        Westbahnhof 11<br />
        38118 Braunschweig, Deutschland <br />
        E-Mail-Adresse: datenschutz@red-oak-consulting.com<br />
        <br />
        <strong>Datenarten, Zwecke der Verarbeitung und Kategorien betroffener Personen</strong><br />
        <p>
          Nachfolgend informieren wir Sie über Art, Umfang und Zweck der Erhebung, Verarbeitung und Nutzung
          personenbezogener Daten.
        </p>
        <strong>1. Arten der Daten, die wir verarbeiten</strong><br />
        Nutzungsdaten (Zugriffszeiten, besuchte Websites etc.), Kommunikationsdaten (IP-Adresse etc.),
        <br />
        <br />
        <strong>2. Zwecke der Verarbeitung nach Art. 13 Abs. 1 c) DS-GVO</strong><br />
        Maßnahmen der Sicherheit, Unterbrechungsfreier,sicherer Betrieb unserer Website,
        <br />
        <br />
        <strong>3. Kategorien der betroffenen Personen nach Art. 13 Abs. 1 e) DS-GVO</strong><br />
        Besucher/Nutzer der Website, <br />
        <br />
        <p>Die betroffenen Personen werden zusammenfassend als "Nutzer“ bezeichnet.</p>
      </Box>
    </Container>
  </Section>
)

const LegalBasisSection = () => (
  <Section id={"LegalBasisSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Rechtsgrundlagen der Verarbeitung personenbezogener Daten</strong>
        <p>
          Nachfolgend Informieren wir Sie über die Rechtsgrundlagen der Verarbeitung personenbezogener
          Daten:
        </p>
        <ol style={{ margin: "10px 0px", padding: "15px" }}>
          <li>
            Wenn wir Ihre Einwilligung für die Verarbeitung personenbezogenen Daten eingeholt haben, ist
            Art. 6 Abs. 1 S. 1 lit. a) DS-GVO Rechtsgrundlage.
          </li>
          <li>
            Ist die Verarbeitung zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher
            Maßnahmen erforderlich, die auf Ihre Anfrage hin erfolgen, so ist Art. 6 Abs. 1 S. 1 lit. b)
            DS-GVO Rechtsgrundlage.
          </li>
          <li>
            Ist die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der wir
            unterliegen (z.B. gesetzliche Aufbewahrungspflichten), so ist Art. 6 Abs. 1 S. 1 lit. c) DS-GVO
            Rechtsgrundlage.
          </li>
          <li>
            Ist die Verarbeitung erforderlich, um lebenswichtige Interessen der betroffenen Person oder
            einer anderen natürlichen Person zu schützen, so ist Art. 6 Abs. 1 S. 1 lit. d) DS-GVO
            Rechtsgrundlage.
          </li>
          <li>
            Ist die Verarbeitung zur Wahrung unserer oder der berechtigten Interessen eines Dritten
            erforderlich und überwiegen diesbezüglich Ihre Interessen oder Grundrechte und Grundfreiheiten
            nicht, so ist Art. 6 Abs. 1 S. 1 lit. f) DS-GVO Rechtsgrundlage.
          </li>
        </ol>
      </Box>
    </Container>
  </Section>
)

const DataSharingSection = () => (
  <Section id={"DataSharingSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Weitergabe personenbezogener Daten an Dritte und Auftragsverarbeiter</strong>
        <p>
          Ohne Ihre Einwilligung geben wir grundsätzlich keine Daten an Dritte weiter. Sollte dies doch der
          Fall sein, dann erfolgt die Weitergabe auf der Grundlage der zuvor genannten Rechtsgrundlagen z.B.
          bei der Weitergabe von Daten an Online-Paymentanbieter zur Vertragserfüllung oder aufgrund
          gerichtlicher Anordnung oder wegen einer gesetzlichen Verpflichtung zur Herausgabe der Daten zum
          Zwecke der Strafverfolgung, zur Gefahrenabwehr oder zur Durchsetzung der Rechte am geistigen
          Eigentum.<br />
          Wir setzen zudem Auftragsverarbeiter (externe Dienstleister z.B. zum Webhosting unserer Websites
          und Datenbanken) zur Verarbeitung Ihrer Daten ein. Wenn im Rahmen einer Vereinbarung zur
          Auftragsverarbeitung an die Auftragsverarbeiter Daten weitergegeben werden, erfolgt dies immer
          nach Art. 28 DS-GVO. Wir wählen dabei unsere Auftragsverarbeiter sorgfältig aus, kontrollieren
          diese regelmäßig und haben uns ein Weisungsrecht hinsichtlich der Daten einräumen lassen. Zudem
          müssen die Auftragsverarbeiter geeignete technische und organisatorische Maßnahmen getroffen haben
          und die Datenschutzvorschriften gem. BDSG n.F. und DS-GVO einhalten
        </p>
      </Box>
    </Container>
  </Section>
)

const DataSharingInternationalSection = () => (
  <Section id={"DataSharingInternationalSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Datenübermittlung in Drittstaaten</strong>
        <p>
          Durch die Verabschiedung der europäischen Datenschutz-Grundverordnung (DS-GVO) wurde eine
          einheitliche Grundlage für den Datenschutz in Europa geschaffen. Ihre Daten werden daher
          vorwiegend durch Unternehmen verarbeitet, für die DS-GVO Anwendung findet. Sollte doch die
          Verarbeitung durch Dienste Dritter außerhalb der Europäischen Union oder des Europäischen
          Wirtschaftsraums stattfinden, so müssen diese die besonderen Voraussetzungen der Art. 44 ff.
          DS-GVO erfüllen. Das bedeutet, die Verarbeitung erfolgt aufgrund besonderer Garantien, wie etwa
          die von der EU-Kommission offiziell anerkannte Feststellung eines der EU entsprechenden
          Datenschutzniveaus oder der Beachtung offiziell anerkannter spezieller vertraglicher
          Verpflichtungen, der so genannten "Standardvertragsklauseln“. <br />
          Soweit wir aufgrund der Unwirksamkeit des sog. "Privacy Shields“, nach Art. 49 Abs. 1 S. 1 lit. a)
          DSGVO die ausdrückliche Einwilligung in die Datenübermittlung in die USA von Ihnen einholen,
          weisen wird diesbezüglich auf das Risiko eines geheimen Zugriffs durch US-Behörden und die Nutzung
          der Daten zu Überwachungszwecken, ggf. ohne Rechtsbehelfsmöglichkeiten für EU-Bürger, hin.
        </p>
      </Box>
    </Container>
  </Section>
)

const DataStorageSection = () => (
  <Section id={"DataStorageSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Löschung von Daten und Speicherdauer</strong>
        <p>
          Sofern nicht in dieser Datenschutzerklärung ausdrücklich angegeben, werden Ihre personenbezogen
          Daten gelöscht oder gesperrt, sobald die zur Verarbeitung erteilte Einwilligung von Ihnen
          widerrufen wird oder der Zweck für die Speicherung entfällt bzw. die Daten für den Zweck nicht
          mehr erforderlich sind, es sei denn deren weitere Aufbewahrung ist zu Beweiszwecken erforderlich
          oder dem stehen gesetzliche Aufbewahrungspflichten entgegenstehen. Darunter fallen etwa
          handelsrechtliche Aufbewahrungspflichten von Geschäftsbriefen nach § 257 Abs. 1 HGB (6 Jahre)
          sowie steuerrechtliche Aufbewahrungspflichten nach § 147 Abs. 1 AO von Belegen (10 Jahre). Wenn
          die vorgeschriebene Aufbewahrungsfrist abläuft, erfolgt eine Sperrung oder Löschung Ihrer Daten,
          es sei denn die Speicherung ist weiterhin für einen Vertragsabschluss oder zur Vertragserfüllung
          erforderlich.
        </p>
      </Box>
    </Container>
  </Section>
)

const AutoDecisionSection = () => (
  <Section id={"AutoDecisionSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Bestehen einer automatisierten Entscheidungsfindung</strong>
        <p>
          Wir setzen keine automatische Entscheidungsfindung oder ein Profiling ein.
        </p>
      </Box>
    </Container>
  </Section>
)

const WebsiteLogSection = () => (
  <Section id={"WebsiteLogSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Bereitstellung unserer Website und Erstellung von Logfiles</strong>
        <ol style={{ margin: "10px 0px", padding: "15px" }}>
          <li>
            Wenn Sie unsere Webseite lediglich informatorisch nutzen (also keine Registrierung und auch
            keine anderweitige Übermittlung von Informationen), erheben wir nur die personenbezogenen Daten,
            die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten,
            erheben wir die folgenden Daten:<br />
            • IP-Adresse;<br />
            • Internet-Service-Provider des Nutzers;<br />
            • Datum und Uhrzeit des Abrufs;<br />
            • Browsertyp;<br />
            • Sprache und Browser-Version;<br />
            • Inhalt des Abrufs;<br />
            • Zeitzone;<br />
            • Zugriffsstatus/HTTP-Statuscode;<br />
            • Datenmenge;<br />
            • Websites, von denen die Anforderung kommt;<br />
            • Betriebssystem.<br />
            Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten von Ihnen findet
            nicht statt.<br />
            <br />
          </li>
          <li>
            Diese Daten dienen dem Zweck der nutzerfreundlichen, funktionsfähigen und sicheren Auslieferung
            unserer Website an Sie mit Funktionen und Inhalten sowie deren Optimierung und statistischen
            Auswertung.<br />
            <br />
          </li>
          <li>
            Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an
            der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.<br />
            <br />
          </li>
          <li>
            Wir speichern aus Sicherheitsgründen diese Daten in Server-Logfiles für die Speicherdauer von 30
            Tagen. Nach Ablauf dieser Frist werden diese automatisch gelöscht, es sei denn wir benötigen
            deren Aufbewahrung zu Beweiszwecken bei Angriffen auf die Serverinfrastruktur oder anderen
            Rechtsverletzungen.<br />
          </li>
        </ol>
      </Box>
    </Container>
  </Section>
)

const ContactViaTypingSection = () => (
  <Section id={"ContactViaTypingSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Kontaktaufnahme per Kontaktformular / E-Mail / Fax / Post</strong>
        <ol style={{ margin: "10px 0px", padding: "15px" }}>
          <li>
            Bei der Kontaktaufnahme mit uns per Kontaktformular, Fax, Post oder E-Mail werden Ihre Angaben
            zum Zwecke der Abwicklung der Kontaktanfrage verarbeitet.<br />
            <br />
          </li>
          <li>
            Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung von Ihnen
            Art. 6 Abs. 1 S. 1 lit. a) DS-GVO. Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge
            einer Kontaktanfrage oder E-Mail, eines Briefes oder Faxes übermittelt werden, ist Art. 6 Abs. 1
            S. 1 lit. f) DS-GVO. Der Verantwortliche hat ein berechtigtes Interesse an der Verarbeitung und
            Speicherung der Daten, um Anfragen der Nutzer beantworten zu können, zur Beweissicherung aus
            Haftungsgründen und um ggf. seiner gesetzlichen Aufbewahrungspflichten bei Geschäftsbriefen
            nachkommen zu können. Zielt der Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche
            Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 S. 1 lit. b) DS-GVO.<br />
            <br />
          </li>
          <li>
            Wir können Ihre Angaben und Kontaktanfrage in unserem Customer-Relationship-Management System
            ("CRM System") oder einem vergleichbaren System speichern.<br />
            <br />
          </li>
          <li>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
            erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und
            diejenigen, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige
            Konversation mit Ihnen beendet ist. Beendet ist die Konversation dann, wenn sich aus den
            Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist. Anfragen
            von Nutzern, die über einen Account bzw. Vertrag mit uns verfügen, speichern wir bis zum Ablauf
            von zwei Jahren nach Vertragsbeendigung. Im Fall von gesetzlichen Archivierungspflichten erfolgt
            die Löschung nach deren Ablauf: Ende handelsrechtlicher (6 Jahre) und steuerrechtlicher (10
            Jahre) Aufbewahrungspflicht.<br />
            <br />
          </li>
          <li>
            Sie haben jederzeit die Möglichkeit, die Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a) DS-GVO zur
            Verarbeitung der personenbezogenen Daten zu widerrufen. Nehmen Sie per E-Mail Kontakt mit uns
            auf, so können Sie der Speicherung der personenbezogenen Daten jederzeit widersprechen.<br />
            <br />
          </li>
        </ol>
      </Box>
    </Container>
  </Section>
)

const ContactViaPhoneSection = () => (
  <Section id={"ContactViaPhoneSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Kontaktaufnahme per Telefon</strong>
        <ol style={{ margin: "10px 0px", padding: "15px" }}>
          <li>
            Bei der Kontaktaufnahme mit uns per Telefon wird Ihre Telefonnummer zur Bearbeitung der
            Kontaktanfrage und deren Abwicklung verarbeitet und temporär im RAM / Cache des Telefongerätes /
            Displays gespeichert bzw. angezeigt. Die Speicherung erfolgt aus Haftungs- und
            Sicherheitsgründen, um den Beweis des Anrufs führen zu können sowie aus wirtschaftlichen
            Gründen, um einen Rückruf zu ermöglichen. Im Falle von unberechtigten Werbeanrufen, sperren wir
            die Rufnummern.<br />
            <br />
          </li>
          <li>
            Rechtsgrundlage für die Verarbeitung der Telefonnummer ist Art. 6 Abs. 1 S. 1 lit. f) DS-GVO.
            Zielt der Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für
            die Verarbeitung Art. 6 Abs. 1 lit. b) DS-GVO.<br />
            <br />
          </li>
          <li>
            Der Gerätecache speichert die Anrufe ca. 80 Tage Tage und überschreibt bzw. löscht sukzessiv
            alte Daten, bei Entsorgung des Gerätes werden alle Daten gelöscht und der Speicher ggf.
            zerstört. Gesperrte Telefonnummer werden jährlich auf die Notwendigkeit der Sperrung geprüft.<br />
            <br />
          </li>
          <li>
            Sie können die Anzeige der Telefonnummer verhindern, indem Sie mit unterdrückter Telefonnummer
            anrufen.<br />
            <br />
          </li>
        </ol>
      </Box>
    </Container>
  </Section>
)

const YoutubeVideoSection = () => (
  <Section id={"YoutubeVideoSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>YouTube-Videos</strong>
        <ol style={{ margin: "10px 0px", padding: "15px" }}>
          <li>
            Wir haben auf unserer Website YouTube-Videos von youtube.com mittels der embedded-Funktion
            eingebunden, so dass diese auf unserer Website direkt aufrufbar sind.
            Wir nutzen die YouTube Funktion No-Cookies, d.h. wir haben Erweiterten Datenschutz aktiviert, 
            Videos werden nicht über youtube.com, sondern über youtube-nocookie.com aufgerufen.
            Diesen stellt YouTube selbst bereit und sichert damit zu, dass YouTube zunächst keine Cookies auf Ihrem Gerät speichert.
            Beim Aufruf der betreffenden Seiten werden allerdings die IP-Adresse sowie die
            in Ziffer 4 genannten weiteren Daten übertragen und so insbesondere mitgeteilt,
            welche unserer Internetseiten Sie besucht haben. Diese Information lässt sich jedoch nicht Ihnen zuordnen,
            wenn Sie bei dem Seitenaufruf bei YouTube oder einem anderen Google-Dienst dauerhaft angemeldet sind.
            Sobald Sie die Wiedergabe eines eingebundenen Videos durch Anklicken starten,
            speichert YouTube durch den erweiterten Datenschutzmodus auf Ihrem Gerät nur Cookies, 
            die keine persönlich identifizierbaren Daten enthalten, es sei denn Sie sind aktuell bei einem Google-Dienst angemeldet.
            Diese Cookies lassen sich durch entsprechende Browsereinstellungen und -erweiterungen verhindern.
            YouTube gehört zur Google Ireland Limited, Registernr.: 368047, Gordon House, Barrow Street, Dublin 4, Irland.<br />
            <br />
          </li>
          <li>
            <strong>Datenkategorie und Beschreibung der Datenverarbeitung:</strong> Nutzungsdaten
            (z.B. aufgerufene Webseite, Inhalte und Zugriffszeiten). Wir haben die Videos im
            sog. "erweiterten Datenschutz-Modus“ eingebunden,
            der nach Anbieterangaben eine Speicherung von Nutzerinformationen erst bei Wiedergabe des Videos in Gang setzt,
            ohne dass mit Cookies das Nutzungsverhalten
            erfasst wird, um die Videowiedergabe zu personalisieren. Stattdessen basieren die
            Videoempfehlungen auf dem aktuell abgespielten Video. Videos, die im erweiterten
            Datenschutzmodus in einem eingebetteten Player wiedergegeben werden, wirken sich nicht darauf
            aus, welche Videos Ihnen auf YouTube empfohlen werden. Beim Start eines Videos (Klick auf das
            Video) willigen Sie ein, dass YouTube die Information trackt, dass Sie die entsprechende
            Unterseite bzw. das Video auf unserer Website aufgerufen haben und diese Daten für Werbezecke
            nutzt.
            <br />
            <br />
          </li>
          <li>
            <strong>Zweck der Verarbeitung:</strong> Bereitstellung eines nutzerfreundlichen Angebots,
            Optimierung und Verbesserung unserer Inhalte.
            <br />
            <br />
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Haben Sie für Verarbeitung Ihrer personenbezogenen Daten
            mittels "etracker“ vom Drittanbieter Ihre Einwilligung erteilt ("Opt-in“), dann ist Art. 6 Abs.
            1 S. 1 lit. a) DS-GVO die Rechtsgrundlage. Rechtsgrundlage ist zudem unser in den obigen Zwecken
            liegendes berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
            Bei Services, die im Zusammenhang mit einem Vertrag erbracht werden, erfolgt das Tracking und
            die Analyse des Nutzerhaltens nach Art. 6 Abs. 1 S. 1 lit. b) DS-GVO, um mit den dadurch
            gewonnen Informationen, optimierte Services zur Erfüllung des Vertragszwecks anbieten zu
            können.<br />
            <br />
          </li>
          <li>
            <strong>Datenübermittlung/Empfängerkategorie:</strong> Drittanbieter in den USA. Die gewonnenen
            Daten werden in die USA &uuml;bertragen und dort gespeichert. Dies erfolgt auch ohne Nutzerkonto
            bei Google. Sollten Sie in Ihren Google-Account eingeloggt sein, kann Google die obigen Daten
            Ihrem Account zuordnen. Wenn Sie dies nicht w&uuml;nschen, m&uuml;ssen Sie sich in Ihrem
            Google-Account ausloggen. Google erstellt aus solchen Daten Nutzerprofile und nutzt diese Daten
            zum Zwecke der Werbung, Marktforschung oder Optimierung seiner Websites.
            Unabhängig von einer Wiedergabe der eingebetteten Videos wird bei jedem Aufruf dieser Webseite eine Verbindung
            zum Google-Netzwerk „DoubleClick“ aufgenommen, was ohne unseren Einfluss weitere Datenverarbeitungsvorgänge auslösen kann.
            Weitere Informationen zum Datenschutz bei YouTube finden Sie in der Datenschutzerklärung des Anbieters unter:
            <br />
            <a href={"https://www.google.de/intl/de/policies/privacy/"} target={"_blank"} rel={"noreferrer"}
            >https://www.google.de/intl/de/policies/privacy/</a
            >.<br />
            <br />
          </li>
          <li>
            <strong>Speicherdauer:</strong> Cookies bis zu 2 Jahre bzw. bis zur Löschung der Cookies durch
            Sie als Nutzer.<br />
            <br />
          </li>
          <li>
            <strong>Widerspruch:</strong> Sie haben gegen&uuml;ber Google ein Widerspruchsrecht gegen die
            Bildung von Nutzerprofilen. Bitte richten Sie sich deswegen direkt an Google &uuml;ber die unten
            genannte Datenschutzerkl&auml;rung. Ein Opt-Out-Widerspruch hinsichtlich derWerbe-Cookies
            k&ouml;nnen Sie hier in Ihrem Google-Account vornehmen:<br />
            <a href={"https://adssettings.google.com/authenticated"} target={"_blank"} rel={"noreferrer"}
            >https://adssettings.google.com/authenticated</a
            >.<br />
            <br />
          </li>
          <li>
            In den Nutzungsbedingungen von YouTube unter&nbsp;<a
            href={"https://www.youtube.com/t/terms"}
            target={"_blank"}
            rel={"noreferrer"}
          >https://www.youtube.com/t/terms</a
          >&nbsp;und in der Datenschutzerkl&auml;rung f&uuml;r Werbung von Google unter&nbsp;<a
            href={"https://policies.google.com/technologies/ads"}
            target={"_blank"}
            rel={"noreferrer"}
          >https://policies.google.com/technologies/ads</a
          >&nbsp;finden Sie weitere Informationen zur<br />
            <br />
          </li>
          <li>
            Verwendung von Google Cookies und deren Werbetechnologien, Speicherdauer, Anonymisierung,
            Standortdaten, Funktionsweise und Ihre Rechte. Allgemeine Datenschutzerklärung von Google:
            <a href={"https://policies.google.com/privacy"} target={"_blank"} rel={"noreferrer"}
            >https://policies.google.com/privacy</a
            >.<br />
            <br />
          </li>
        </ol>
      </Box>
    </Container>
  </Section>
)

const ApplicationPrivacySection = () => (
  <Section id={"ApplicationPrivacySection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Datenschutz bei Bewerbungen und im Bewerberverfahren</strong>
        <ol style={{ margin: "10px 0px", padding: "15px" }}>
          <li>
            Bewerbungen, die auf elektronischem Wege oder per Post an den Verantwortlichen gesendet werden,
            werden zum Zwecke der Abwicklung des Bewerberverfahrens elektronisch oder manuell
            verarbeitet.<br />
            <br />
          </li>
          <li>
            Wir weisen ausdrücklich darauf hin, dass Bewerbungsunterlagen mit "besonderen Kategorien
            personenbezogener Daten“ nach Art. 9 DS-GVO (z.B. ein Foto, welches Rückschlüsse auf Ihre
            ethnische Herkunft, Religion oder Ihren Familienstand gibt), mit Ausnahme einer eventuellen
            Schwerbehinderung, welche Sie aus freier Entscheidung offenlegen möchten, unerwünscht sind. Sie
            sollen Ihre Bewerbung ohne diese Daten einreichen. Dies hat keine Auswirkungen auf Ihre
            Bewerberchancen.<br />
            <br />
          </li>
          <li>
            Rechtsgrundlagen für die Verarbeitung sind Art. 6 Abs. 1 S.1 lit. b) DS-GVO sowie § 26 BDSG
            n.F.<br />
            <br />
          </li>
          <li>
            Wird nach Abschluss des Bewerberverfahrens, ein Arbeitsverhältnis mit dem Bewerber / der
            Bewerberin eingegangen, werden die Bewerberdaten unter Beachtung einschlägiger
            Datenschutzvorschriften gespeichert. Wird Ihnen nach Abschluss des Bewerberverfahrens keine
            Stelle angeboten, so wird Ihr eingereichtes Bewerbungsschreiben samt Unterlagen 6 Monate nach
            Versand der Absage gelöscht, um etwaigen Ansprüchen und Nachweispflichten nach AGG genügen zu
            können.<br />
            <br />
          </li>
        </ol>
      </Box>
    </Container>
  </Section>
)

const RevocationSection = () => (
  <Section id={"RevocationSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Rechte der betroffenen Person</strong>
        <ol style={{ margin: "10px 0px", padding: "15px" }}>
          <li>
            <strong> Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</strong> <br />
              Soweit die Verarbeitung auf Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a), Art. 7 DS-GVO
              beruht, haben Sie das Recht, die Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der
              aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird dadurch nicht
              berührt.<br />
              <br />
              Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung gemäß
              Art. 6 Abs. 1 S. 1 lit. f) DS-GVO stützen, können SieWiderspruch gegen die Verarbeitung
              einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines
              Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung
              der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um
              Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt
              verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und
              werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden
              schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.<br />
              <br />
              Sie können der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und
              Datenanalyse jederzeit widersprechen. Das Widerspruchsrecht können Sie kostenfrei ausüben.
              Über Ihren Werbewiderspruch können Sie uns unter folgenden Kontaktdaten informieren:<br />
              <br />
              Red Oak Consulting & Services for IT GmbH & Co. KG<br />
              Am Gemüsegarten 7<br />
              38302, Wolfenbüttel, Deutschland<br />
              E-Mail-Adresse: datenschutz@red-oak-consulting.com<br />
              </li>
           
            <br />
          <li>
            <strong>Recht auf Auskunft</strong><br />
            Sie haben ein Recht auf Auskunft über Ihre bei uns gespeicherten persönlichen Daten nach Art. 15
            DS-GVO. Dies beinhaltet insbesondere die Auskunft über die Verarbeitungszwecke, die Kategorie
            der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten
            offengelegt wurden oder werden, die geplante Speicherdauer, die Herkunft ihrer Daten, sofern
            diese nicht direkt bei Ihnen erhoben wurden.<br />
            <br />
          </li>
          <li>
            <strong>Recht auf Berichtigung</strong><br />
            Sie haben ein Recht auf Berichtigung unrichtiger oder auf Vervollständigung richtiger Daten nach
            Art. 16 DS-GVO.
            <br />
            <br />
          </li>
          <li>
            <strong>Recht auf Löschung</strong><br />
            Sie haben ein Recht auf Löschung Ihrer bei uns gespeicherten Daten nach Art. 17 DS-GVO, es sei
            denn gesetzliche oder vertraglichen Aufbewahrungsfristen oder andere gesetzliche Pflichten bzw.
            Rechte zur weiteren Speicherung stehen dieser entgegen.
            <br />
            <br />
          </li>
          <li>
            <strong>Recht auf Einschränkung</strong><br />
            Sie haben das Recht, eine Einschränkung bei der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen, wenn eine der Voraussetzungen in Art. 18 Abs. 1 lit. a) bis d) DS-GVO erfüllt ist:<br />
            • Wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die
            es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu
            überprüfen;<br />
            <br />
            • die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen
            und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;<br />
            <br />
            • der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger
            benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            benötigen, oder<br />
            <br />
            • wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DS-GVO eingelegt haben und
            noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen
            überwiegen.<br />
            <br />
          </li>
          <li>
            <strong>Recht auf Datenübertragbarkeit</strong><br />
            Sie haben ein Recht auf Datenübertragbarkeit nach Art. 20 DS-GVO, was bedeutet, dass Sie die bei
            uns über Sie gespeicherten personenbezogenen Daten in einem strukturierten, gängigen und
            maschinenlesbaren Format erhalten können oder die Übermittlung an einen anderen Verantwortlichen
            verlangen können.
            <br />
            <br />
          </li>
          <li>
            <strong>Recht auf Beschwerde</strong><br />
            Sie haben ein Recht auf Beschwerde bei einer Aufsichtsbehörde. In der Regel können Sie sich
            hierfür an die Aufsichtsbehörde insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres
            Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes wenden.
            <br />
            <br />
          </li>
        </ol>
      </Box>
    </Container>
  </Section>
)

const DataPrivacySection = () => (
  <Section id={"DataPrivacySection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Datensicherheit</strong>
        <p>
          Um alle personenbezogen Daten, die an uns übermittelt werden, zu schützen und um sicherzustellen,
          dass die Datenschutzvorschriften von uns, aber auch unseren externen Dienstleistern eingehalten
          werden, haben wir geeignete technische und organisatorische Sicherheitsmaßnahmen getroffen.
          Deshalb werden unter anderem alle Daten zwischen Ihrem Browser und unserem Server über eine
          sichere SSL-Verbindung verschlüsselt übertragen.
        </p>
      </Box>
    </Container>
  </Section>
)

const ReferenceSection = () => (
  <Section id={"ReferenceSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <strong>Stand: 11.08.2020</strong>
        <p>
          Quelle:&ensp;
          <a href={"https://www.juraforum.de/datenschutzerklaerung-muster/"} target={"_blank"} rel={"noreferrer"}
          >Muster-Datenschutzerklärung von JuraForum.de</a>
        </p>
      </Box>
    </Container>
  </Section>
)

export default DataPrivacyPage

export const query = graphql`
    query DataPrivacyQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`